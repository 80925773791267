<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            v-loading="loadFlag"
            :data="tableData.filter(data => !search || data.perm_name.toLowerCase().includes(search.toLowerCase()))"
            border
            class="vg_cursor"
            @row-dblclick="dbClickJp"
          >
            <el-table-column label="数据名称" prop="perm_name">
              <template slot="header" slot-scope="scope">
                <div style="margin-right: 10px">
                  数据名称
                  <el-input v-model="search" placeholder="输入关键字搜索" size="mini" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';

export default {
  name: 'OptnList',
  data() {
    return {
      tableData: [],
      multiSelection: [],
      loadFlag: true,
      search: ''
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getOptns();
    },
    // 获取信息
    getOptns() {
      get(optnAPI.getAllOptns, {
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getOptns();
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/optn_edit', { perm_id: row.perm_id });
    }
  }
};
</script>

<style scoped></style>
